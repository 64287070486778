const crewIdentity = {
    "agung-wijayanto" : {
        name : "Bambang Agung Wijayanto ",
        job_position : "Data Architect",
        email : "gung@sibernetik.co.id",
        address : "Equity Tower, 35th Floor. Unit D, G, H. Jl. Jend. Sudirman Kav. 52-53 (SCBD). Kebayoran Baru, Jakarta 12190, Indonesia.",
        phone: "+62 812-1287-0330",
        password: "",
        photoKaryawan : "agung-img",
    },
    "andreas-herdian" : {
        name : "Andreas Herdian",
        job_position : "Sales Manager",
        email : "and@sibernetik.co.id",
        address : "Equity Tower, 35th Floor. Unit D, G, H. Jl. Jend. Sudirman Kav. 52-53 (SCBD). Kebayoran Baru, Jakarta 12190, Indonesia.",
        phone: "+62 812-9989-8008",
        password: "",
        photoKaryawan : "andre-img",
    },
    "arigo-fahreza" : { 
        name : "Arigo Fahreza Putratama",
        job_position : "Data Engineer",
        email : "ari@sibernetik.co.id",
        address : "Equity Tower, 35th Floor. Unit D, G, H. Jl. Jend. Sudirman Kav. 52-53 (SCBD). Kebayoran Baru, Jakarta 12190, Indonesia.",
        phone: "+62 812-3747-6923",
        password: "",
        photoKaryawan : "arigo-img",
    },
    "deepika-gupita" : {
        name : "Deepika Gupta",
        job_position : "Data Engineer",
        email : "deepa@sibernetik.co.id",
        address : "Equity Tower, 35th Floor. Unit D, G, H. Jl. Jend. Sudirman Kav. 52-53 (SCBD). Kebayoran Baru, Jakarta 12190, Indonesia.",
        phone: "08119141160",
        password: "",
        photoKaryawan : "deepika-img",
    },
    "aditya-ghifari" : {
        name : "Aditya Ghifari Pratama",
        job_position : "Security Architect",
        email : "far@sibernetik.co.id",
        address : "Equity Tower, 35th Floor. Unit D, G, H. Jl. Jend. Sudirman Kav. 52-53 (SCBD). Kebayoran Baru, Jakarta 12190, Indonesia.",
        phone: "+62 821-1974-1142",
        password: "",
        photoKaryawan : "ghifar-img",
    },
    "johanes-parasi" : {
        name : "Johanes Parasi",
        job_position : "Data Engineer",
        email : "joe@sibernetik.co.id",
        address : "Equity Tower, 35th Floor. Unit D, G, H. Jl. Jend. Sudirman Kav. 52-53 (SCBD). Kebayoran Baru, Jakarta 12190, Indonesia.",
        phone: "+62 878-8370-6793",
        password: "",
        photoKaryawan : "johanes-img",
    },
    "lutvi-handayani" : {
        name : "Lutvi Handayani",
        job_position : "Sales",
        email : "vi@sibernetik.co.id",
        address : "Equity Tower, 35th Floor. Unit D, G, H. Jl. Jend. Sudirman Kav. 52-53 (SCBD). Kebayoran Baru, Jakarta 12190, Indonesia.",
        phone: "+62 816-4824-590",
        password: "",
        photoKaryawan : "lutvi-img",
    },
    "meiliana-bursa" : {
        name : "Meiliana Bursa",
        job_position : "Finance Accounting Tax",
        email : "mei@sibernetik.co.id",
        address : "Equity Tower, 35th Floor. Unit D, G, H. Jl. Jend. Sudirman Kav. 52-53 (SCBD). Kebayoran Baru, Jakarta 12190, Indonesia.",
        phone: "081286037427",
        password: "",
        photoKaryawan : "mei-img",
    },
    "sudedy" : {
        name : "Sudedy Widjaya",
        job_position : "Senior Data Engineer",
        email : "ded@sibernetik.co.id",
        address : "Equity Tower, 35th Floor. Unit D, G, H. Jl. Jend. Sudirman Kav. 52-53 (SCBD). Kebayoran Baru, Jakarta 12190, Indonesia.",
        phone: "+62 813-9893-6073",
        password: "",
        photoKaryawan : "dedy-img",
    },
    "andreas-william" : {
        name : "Andreas William",
        job_position : "",
        email : "wil@sibernetik.co.id",
        address : "Equity Tower, 35th Floor. Unit D, G, H. Jl. Jend. Sudirman Kav. 52-53 (SCBD). Kebayoran Baru, Jakarta 12190, Indonesia.",
        phone: "+62818487286",
        password: "",
        photoKaryawan : "william-img",
    },
    "wildan-nuryulda" : {
        name : "Mohammad Wildan Nuryulda",
        job_position : "Data Engineer",
        email : "nur@sibernetik.co.id",
        address : "Equity Tower, 35th Floor. Unit D, G, H. Jl. Jend. Sudirman Kav. 52-53 (SCBD). Kebayoran Baru, Jakarta 12190, Indonesia.",
        phone: "+62 813-8570-9410",
        password: "",
        photoKaryawan : "wildan-img",
        
    },
    "ambrosius" : {
        name : "Ambrosius Daniel Gerald Frederick Maitimo",
        job_position : "Project Manager",
        email : "bro@sibernetik.co.id",
        address : "Equity Tower, 35th Floor. Unit D, G, H. Jl. Jend. Sudirman Kav. 52-53 (SCBD). Kebayoran Baru, Jakarta 12190, Indonesia.",
        phone: "+62 896-3757-2014",
        password: "",
        photoKaryawan : "ambro-img",
    },
    "sanchia-enola" : {
        name : "Sanchia Aryana Enola Lambey",
        job_position : "Junior Sales",
        email : "eno@sibernetik.co.id",
        address : "Equity Tower, 35th Floor. Unit D, G, H. Jl. Jend. Sudirman Kav. 52-53 (SCBD). Kebayoran Baru, Jakarta 12190, Indonesia.",
        phone: "+62 877-8467-9630",
        password: "",
        photoKaryawan : "enola-img",
    },
    "najril-adla" : {
        name : "Muhammad Najril Adla",
        job_position : "Office Boy",
        email : "najril.adla@sibernetik.co.id",
        address : "Equity Tower, 35th Floor. Unit D, G, H. Jl. Jend. Sudirman Kav. 52-53 (SCBD). Kebayoran Baru, Jakarta 12190, Indonesia.",
        phone: "+62 857-1986-5601",
        password: "",
        photoKaryawan : "najril-img",
    },
    "muhamad-rizqi" : {
        name : "Muhamad Rizqi",
        job_position : "Network & Security Engineer",
        email : "riz@sibernetik.co.id",
        address : "Equity Tower, 35th Floor. Unit D, G, H. Jl. Jend. Sudirman Kav. 52-53 (SCBD). Kebayoran Baru, Jakarta 12190, Indonesia.",
        phone: "+62 812-8375-3419",
        password: "",
        photoKaryawan : "rizqy-img",
    },
    "fadli-triaji" : {
        name : "Muhammad Fadli",
        job_position : "Network & Security Engineer",
        email : "fad@sibernetik.co.id",
        address : "Equity Tower, 35th Floor. Unit D, G, H. Jl. Jend. Sudirman Kav. 52-53 (SCBD). Kebayoran Baru, Jakarta 12190, Indonesia.",
        phone: "+6282162909510",
        password: "",
        photoKaryawan : "fadli-img",
    },
    "adela-cahya-nug" : {
        name : "Adela Cahya Nugraha",
        job_position : "Fullstack Developer",
        email : "del@sibernetik.co.id",
        address : "Equity Tower, 35th Floor. Unit D, G, H. Jl. Jend. Sudirman Kav. 52-53 (SCBD). Kebayoran Baru, Jakarta 12190, Indonesia.",
        phone: "+6285718315615",
        password: "",
        photoKaryawan : "adela-img",
    },
    "akbar-panuja" : {
        name : "Rachmat Akbar Panuja ",
        job_position : "Data Engineer",
        email : "bar@sibernetik.co.id",
        address : "Equity Tower, 35th Floor. Unit D, G, H. Jl. Jend. Sudirman Kav. 52-53 (SCBD). Kebayoran Baru, Jakarta 12190, Indonesia.",
        phone: "+6281289863103",
        password: "",
        photoKaryawan : "akbar-img",
    },
    "andini" : {
        name : "Andini",
        job_position : "Junior Sales",
        email : "din@sibernetik.co.id",
        address : "Equity Tower, 35th Floor. Unit D, G, H. Jl. Jend. Sudirman Kav. 52-53 (SCBD). Kebayoran Baru, Jakarta 12190, Indonesia.",
        phone: "+62 812-1221-7823",
        password: "",
        photoKaryawan : "dini-img",
    },
    "arief-hasani" : {
        name : "Arief Hasani",
        job_position : "Technology & Operation Delivery General Manager",
        email : "har@sibernetik.co.id",
        address : "Equity Tower, 35th Floor. Unit D, G, H. Jl. Jend. Sudirman Kav. 52-53 (SCBD). Kebayoran Baru, Jakarta 12190, Indonesia.",
        phone: "+62 815-7469-7988",
        password: "",
        photoKaryawan : "arief-img",
    },
    "beny-prabowo" : {
        name : "Beny Prabowo",
        job_position : "Direktur",
        email : "ben@sibernetik.co.id",
        address : "Equity Tower, 35th Floor. Unit D, G, H. Jl. Jend. Sudirman Kav. 52-53 (SCBD). Kebayoran Baru, Jakarta 12190, Indonesia.",
        phone: "+62 816-910-768",
        password: "",
        photoKaryawan : "ben-img",
    },
    "clauri-pasaribu" : {
        name : "Clauri Pasaribu",
        job_position : "Data Engineer",
        email : "cla@sibernetik.co.id",
        address : "Equity Tower, 35th Floor. Unit D, G, H. Jl. Jend. Sudirman Kav. 52-53 (SCBD). Kebayoran Baru, Jakarta 12190, Indonesia.",
        phone: "+62 823-6514-2190",
        password: "",
        photoKaryawan : "clauri-img",
    },
    "ikhsan-ryd" : {
        name : "Ikhsan Riyadi",
        job_position : "Senior Presale",
        email : "san@sibernetik.co.id",
        address : "Equity Tower, 35th Floor. Unit D, G, H. Jl. Jend. Sudirman Kav. 52-53 (SCBD). Kebayoran Baru, Jakarta 12190, Indonesia.",
        phone: "+6281806589679",
        password: "",
        photoKaryawan : "ikhsan-img",
    },
    "judho-pwr" : {
        name : "Judho Perwiro",
        job_position : "System Engineer",
        email : "jud@sibernetik.co.id",
        address : "Equity Tower, 35th Floor. Unit D, G, H. Jl. Jend. Sudirman Kav. 52-53 (SCBD). Kebayoran Baru, Jakarta 12190, Indonesia.",
        phone: "+62 819-1555-6642",
        password: "",
        photoKaryawan : "judho-img",
    },
    "malik-aziz" : {
        name : "Malik Aziz Habibie",
        job_position : "Front-end Developer",
        email : "lik@sibernetik.co.id",
        address : "Equity Tower, 35th Floor. Unit D, G, H. Jl. Jend. Sudirman Kav. 52-53 (SCBD). Kebayoran Baru, Jakarta 12190, Indonesia.",
        phone: "081297222072",
        password: "",
        photoKaryawan : "malik-img",
    },
    "yusuf-adhiryanto" : {
        name : "Muhammad Yusuf Adhiryanto",
        job_position : "Fullstack Developer",
        email : "suf@sibernetik.co.id",
        address : "Equity Tower, 35th Floor. Unit D, G, H. Jl. Jend. Sudirman Kav. 52-53 (SCBD). Kebayoran Baru, Jakarta 12190, Indonesia.",
        phone: "+62 878-7824-4652",
        password: "",
        photoKaryawan : "yusuf-img",
    },
    "raditya-eko" : {
        name : "Raditya Eko Prabowo",
        job_position : "Fullstack Developer",
        email : "dit@sibernetik.co.id",
        address : "Equity Tower, 35th Floor. Unit D, G, H. Jl. Jend. Sudirman Kav. 52-53 (SCBD). Kebayoran Baru, Jakarta 12190, Indonesia.",
        phone: "+62 852-8513-7224",
        password: "",
        photoKaryawan : "radit-img",
    },
    "vengky" : {
        name : "Vengky Fahrudy Kp",
        job_position : "Technical PM and Information Security Engineer",
        email : "kik@sibernetik.co.id",
        address : "Equity Tower, 35th Floor. Unit D, G, H. Jl. Jend. Sudirman Kav. 52-53 (SCBD). Kebayoran Baru, Jakarta 12190, Indonesia.",
        phone: "+6282335889800",
        password: "",
        photoKaryawan : "vengky-img",
    },
    "rulsyah" : {
        name : "Rulsyah",
        job_position : "-",
        email : "rul@sibernetik.co.id",
        address : "Equity Tower, 35th Floor. Unit D, G, H. Jl. Jend. Sudirman Kav. 52-53 (SCBD). Kebayoran Baru, Jakarta 12190, Indonesia.",
        phone: "+62 812-7482-8225",
        password: "",
        photoKaryawan : "rulsyah-img",
    },
    "stephen-yin-soen" : {
        name : "Stephen Yin Soen",
        job_position : "Direktur Utama",
        email : "phen@sibernetik.co.id",
        address : "Equity Tower, 35th Floor. Unit D, G, H. Jl. Jend. Sudirman Kav. 52-53 (SCBD). Kebayoran Baru, Jakarta 12190, Indonesia.",
        phone: "+62 811-890-827",
        password: "",
        photoKaryawan : "stephen-img",
    },
    "pramudia-febriansyah" : {
        name : "Muhammad Pramudia Febriansyah",
        job_position : "Junior Network & Security Engineer",
        email : "pram@sibernetik.co.id",
        address : "Equity Tower, 35th Floor. Unit D, G, H. Jl. Jend. Sudirman Kav. 52-53 (SCBD). Kebayoran Baru, Jakarta 12190, Indonesia.",
        phone: "081398754497",
        password: "",
        photoKaryawan : "febriansyah-img",
    },
    // ... existing code ...
    "fauziyah-rizqi" : {
        name : "Fauziyah Rizqi Ramdhani",
        job_position : "Digital Marketing",
        email : "fau@sibernetik.co.id",
        address : "Equity Tower, 35th Floor. Unit D, G, H. Jl. Jend. Sudirman Kav. 52-53 (SCBD). Kebayoran Baru, Jakarta 12190, Indonesia.",
        phone: "081327766104",
        password: "",
        photoKaryawan : "fauziyahrizqi-img",
    },
    "ester-victoriana" : {
        name : "Ester Victoriana Caroline",
        job_position : "Business Development",
        email : "est@sibernetik.co.id",
        address : "Equity Tower, 35th Floor. Unit D, G, H. Jl. Jend. Sudirman Kav. 52-53 (SCBD). Kebayoran Baru, Jakarta 12190, Indonesia.",
        phone: "082120431822",
        password: "",
        photoKaryawan : "ester-img",
    },
    "ardianto-surya-nug" : {
        name : "Ardianto Surya Nugraha",
        job_position : "Business Development",
        email : "asn@sibernetik.co.id",
        address : "Equity Tower, 35th Floor. Unit D, G, H. Jl. Jend. Sudirman Kav. 52-53 (SCBD). Kebayoran Baru, Jakarta 12190, Indonesia.",
        phone: "08119628789",
        password: "",
        photoKaryawan : "ardi-img",
    },
    "adial-muhsin" : {
        name : "Adial Muhsin Teguh Pratama",
        job_position : "Stream Engineer",
        email : "dial@sibernetik.co.id",
        address : "Equity Tower, 35th Floor. Unit D, G, H. Jl. Jend. Sudirman Kav. 52-53 (SCBD). Kebayoran Baru, Jakarta 12190, Indonesia.",
        phone: "08979600066",
        password: "",
        photoKaryawan : "adial-img",
    },
    "chresto-akbar": {
        name: "Chresto Akbar",
        job_position: "Human Capital",
        email: "chres@sibernetik.co.id",
        address: "Equity Tower, 35th Floor. Unit D, G, H. Jl. Jend. Sudirman Kav. 52-53 (SCBD). Kebayoran Baru, Jakarta 12190, Indonesia.",
        phone: "08170030041",
        password: "",
        photoKaryawan: "chresto-img",
    },
    "cindy-novianti": {
        name: "Cindy Novianti",
        job_position: "Project Manager",
        email: "nov@sibernetik.co.id",
        address: "Equity Tower, 35th Floor. Unit D, G, H. Jl. Jend. Sudirman Kav. 52-53 (SCBD). Kebayoran Baru, Jakarta 12190, Indonesia.",
        phone: "089518453496",
        password: "",
        photoKaryawan: "cindy-img",
    },
    "daffa-aldin": {
        name: "Daffa Aldin",
        job_position: "Data Engineer",
        email: "daf@sibernetik.co.id",
        address: "Equity Tower, 35th Floor. Unit D, G, H. Jl. Jend. Sudirman Kav. 52-53 (SCBD). Kebayoran Baru, Jakarta 12190, Indonesia.",
        phone: "082122331319",
        password: "",
        photoKaryawan: "daffa-img",
    },
    "hanania-peter": {
        name: "Hanania Peter Sutomo",
        job_position: "Network & Security Engineer",
        email: "han@sibernetik.co.id",
        address: "Equity Tower, 35th Floor. Unit D, G, H. Jl. Jend. Sudirman Kav. 52-53 (SCBD). Kebayoran Baru, Jakarta 12190, Indonesia.",
        phone: "087886827762",
        password: "",
        photoKaryawan: "hanan-img",
    },
    "ipik-yuana": {
        name: "Ipik Yuana Putra",
        job_position: "Data Architect",
        email: "pik@sibernetik.co.id",
        address: "Equity Tower, 35th Floor. Unit D, G, H. Jl. Jend. Sudirman Kav. 52-53 (SCBD). Kebayoran Baru, Jakarta 12190, Indonesia.",
        phone: "082112300824",
        password: "",
        photoKaryawan: "ipik-img",
    },
    "iwang-moeslem": {
        name: "Iwang Moeslem Andika Surya",
        job_position: "Data Engineer",
        email: "wang@sibernetik.co.id",
        address: "Equity Tower, 35th Floor. Unit D, G, H. Jl. Jend. Sudirman Kav. 52-53 (SCBD). Kebayoran Baru, Jakarta 12190, Indonesia.",
        phone: "081249178464",
        password: "",
        photoKaryawan: "iwang-img",
    },
    "jeremiah-yostian": {
        name: "Jeremiah Yostian Suprapto",
        job_position: "Business Development",
        email: "jer@sibernetik.co.id",
        address: "Equity Tower, 35th Floor. Unit D, G, H. Jl. Jend. Sudirman Kav. 52-53 (SCBD). Kebayoran Baru, Jakarta 12190, Indonesia.",
        phone: "0881082156138",
        password: "",
        photoKaryawan: "jerry-img",
    },
    "kevin-rafi": {
        name: "Kevin Rafi Adjie Putra Santoso",
        job_position: "Stream Engineer",
        email: "vin@sibernetik.co.id",
        address: "Equity Tower, 35th Floor. Unit D, G, H. Jl. Jend. Sudirman Kav. 52-53 (SCBD). Kebayoran Baru, Jakarta 12190, Indonesia.",
        phone: "085903758956",
        password: "",
        photoKaryawan: "kevin-img",
    },
    "mohammad-firdaus": {
        name: "Mohammad Firdaus Saeful Amri",
        job_position: "DevSec Ops",
        email: "daus@sibernetik.co.id",
        address: "Equity Tower, 35th Floor. Unit D, G, H. Jl. Jend. Sudirman Kav. 52-53 (SCBD). Kebayoran Baru, Jakarta 12190, Indonesia.",
        phone: "082133551093",
        password: "",
        photoKaryawan: "firdaus-img",
    },
    "muhammad-firzan": {
        name: "Muhammad Firzan Ananta",
        job_position: "Data Engineer",
        email: "fir@sibernetik.co.id",
        address: "Equity Tower, 35th Floor. Unit D, G, H. Jl. Jend. Sudirman Kav. 52-53 (SCBD). Kebayoran Baru, Jakarta 12190, Indonesia.",
        phone: "087889602858",
        password: "",
        photoKaryawan: "firzan-img",
    },
    "muhammad-raihan": {
        name: "Muhammad Raihan",
        job_position: "Data Engineer",
        email: "rai@sibernetik.co.id",
        address: "Equity Tower, 35th Floor. Unit D, G, H. Jl. Jend. Sudirman Kav. 52-53 (SCBD). Kebayoran Baru, Jakarta 12190, Indonesia.",
        phone: "081291760330",
        password: "",
        photoKaryawan: "raihan-img",
    },
    "mutiara-indy": {
        name: "Mutiara Indy Danuarta",
        job_position: "Finance Accounting and Tax",
        email: "mut@sibernetik.co.id",
        address: "Equity Tower, 35th Floor. Unit D, G, H. Jl. Jend. Sudirman Kav. 52-53 (SCBD). Kebayoran Baru, Jakarta 12190, Indonesia.",
        phone: "087765143850",
        password: "",
        photoKaryawan: "tiara-img",
    },
    "namira-savira": {
        name: "Namira Savira",
        job_position: "Recruiter",
        email: "nam@sibernetik.co.id",
        address: "Equity Tower, 35th Floor. Unit D, G, H. Jl. Jend. Sudirman Kav. 52-53 (SCBD). Kebayoran Baru, Jakarta 12190, Indonesia.",
        phone: "085642012171",
        password: "",
        photoKaryawan: "namira-img",
    },
    "ocarina": {
        name: "Ocarina Cloramidina",
        job_position: "Data Engineer",
        email: "oca@sibernetik.co.id",
        address: "Equity Tower, 35th Floor. Unit D, G, H. Jl. Jend. Sudirman Kav. 52-53 (SCBD). Kebayoran Baru, Jakarta 12190, Indonesia.",
        phone: "081279669492",
        password: "",
        photoKaryawan: "oca-img",
    },
    "raden-alvin": {
        name: "Raden Alvin Putra",
        job_position: "Data Architect",
        email: "alv@sibernetik.co.id",
        address: "Equity Tower, 35th Floor. Unit D, G, H. Jl. Jend. Sudirman Kav. 52-53 (SCBD). Kebayoran Baru, Jakarta 12190, Indonesia.",
        phone: "082110713972",
        password: "",
        photoKaryawan: "alvin-img",
    },
    "romi": {
        name: "Romi",
        job_position: "Data Engineer",
        email: "rom@sibernetik.co.id",
        address: "Equity Tower, 35th Floor. Unit D, G, H. Jl. Jend. Sudirman Kav. 52-53 (SCBD). Kebayoran Baru, Jakarta 12190, Indonesia.",
        phone: "",
        password: "",
        photoKaryawan: "romi-img",
    },
    "salsabila-ervina": {
        name: "Salsabila Ervina Puspitaningtyas",
        job_position: "Data Engineer",
        email: "sal@sibernetik.co.id",
        address: "Equity Tower, 35th Floor. Unit D, G, H. Jl. Jend. Sudirman Kav. 52-53 (SCBD). Kebayoran Baru, Jakarta 12190, Indonesia.",
        phone: "085159215667",
        password: "",
        photoKaryawan: "salsa-img",
    },
    "yehezkiel-tatang": {
        name: "Yehezkiel Tatang",
        job_position: "Developer",
        email: "yeh@sibernetik.co.id",
        address: "Equity Tower, 35th Floor. Unit D, G, H. Jl. Jend. Sudirman Kav. 52-53 (SCBD). Kebayoran Baru, Jakarta 12190, Indonesia.",
        phone: "085765736145",
        password: "",
        photoKaryawan: "tatang-img",
    },
    "yohanes-calvin": {
        name: "Yohanes Calvin Salim",
        job_position: "Infra Engineer",
        email: "cal@sibernetik.co.id",
        address: "Equity Tower, 35th Floor. Unit D, G, H. Jl. Jend. Sudirman Kav. 52-53 (SCBD). Kebayoran Baru, Jakarta 12190, Indonesia.",
        phone: "08984687288",
        password: "",
        photoKaryawan: "calvin-img",
    },
    "diky-satria": {
        name: "Diky Satria Ramadanu",
        job_position: "Fullstack Developer",
        email: "dik@sibernetik.co.id",
        address: "Equity Tower, 35th Floor. Unit D, G, H. Jl. Jend. Sudirman Kav. 52-53 (SCBD). Kebayoran Baru, Jakarta 12190, Indonesia.",
        phone: "085224793967",
        password: "",
        photoKaryawan: "diky-img",
    },
    "imam-sanjaya": {
        name: "Imam Sanjaya",
        job_position: "Business Analyst",
        email: "mam@sibernetik.co.id",
        address: "Equity Tower, 35th Floor. Unit D, G, H. Jl. Jend. Sudirman Kav. 52-53 (SCBD). Kebayoran Baru, Jakarta 12190, Indonesia.",
        phone: "085691644607",
        password: "",
        photoKaryawan: "imam-img",
    },

// ... existing code continues ...

    
}
export function crewDigitalCard(crewDetail) {
    // test123
    return crewIdentity.hasOwnProperty(crewDetail) ? crewIdentity[crewDetail] : null
}

export const employeeData = crewIdentity