<template>
  <div>
    <v-card class="ma-auto px-5 pt-8 card-styling elevation-24" width="400" max-height="680" color="#333333" light style="position: relative;">
      <img src="../assets/images/logo/SIDlogo.webp" style="height: 60px; width: auto;" class="mx-auto"/>
      <v-card-title class="d-flex flex-column justify-center">
        <div class="rounded-pill pa-1" style="background-color: #dd8732">
          <v-img :src="require(`../assets/images/crew/${getImage()}`)" class="rounded-pill" height="15vh"
            width="15vh" />
        </div>
      </v-card-title>
      <v-card-text class="d-flex flex-column justify-center" style="position: relative;">
        <div class="d-flex flex-row">
          <v-btn class="mx-2 no-hover-effect" icon dark large small>
            <v-icon dark color="#dd8732">
              mdi-account
            </v-icon>
          </v-btn>
          <span class="mt-3 disc">{{ name }}</span>
        </div>
        <div class="d-flex flex-row">
          <v-btn class="mx-2 no-hover-effect" icon dark large small>
            <v-icon dark color="#dd8732">
              mdi-briefcase-variant
            </v-icon>
          </v-btn>
          <span class="mt-3 disc">{{ job_position }}</span>
        </div>
        <div class="d-flex flex-row">
          <v-btn class="mx-2 no-hover-effect" icon dark large small>
            <v-icon dark color="#dd8732">
              mdi-phone
            </v-icon>
          </v-btn>
          <div class="mt-3">
            <span class="disc">t : +622129277994</span>
            <br>
            <span class="mt-3 disc">m : {{ phone }}</span>
          </div>
        </div>
        <div class="d-flex flex-row">
          <v-btn class="mx-2 no-hover-effect" icon dark large small>
            <v-icon dark color="#dd8732">
              mdi-email
            </v-icon>
          </v-btn>
          <span class="mt-3 disc">{{ email }}</span>
        </div>
        <!-- Add new address section -->
        <div class="d-flex flex-row">
          <v-btn class="mx-2 no-hover-effect" icon dark large small>
            <v-icon dark color="#dd8732">
              mdi-map-marker
            </v-icon>
          </v-btn>
          <span class="mt-3 disc">{{ address }}</span>
        </div>
        <!-- End of new section -->
        <div class="d-flex flex-row mt-5">
          <v-btn v-on:click="downloadFile()" class="rounded-md" color="#dd8732" dark block large>
            <v-icon dark>
              mdi-account-multiple-plus
            </v-icon> &nbsp;
            Add Contact
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
    <!-- <div class="d-flex flex-column px-auto mt-5">
      <span class="styling-principal">Principal Products</span>
      <hr class="ma-auto" style="border: 3px solid #dd8732; border-radius: 1px; width: 150px" />
    </div> -->
    <!-- <v-row class="mt--30">
      <v-col cols="12">
        <ul class="brand-style d-flex justify-center">
          <li v-for="(brand, i) in brandImages" :key="i">
            <a :href="brand.url">
              <img :src="brand.src" alt="Logo Images" />
            </a>
          </li>
        </ul>
      </v-col>
    </v-row> -->
    <v-container class="mt-5 d-flex justify-center">
      <img src="../assets/images/logo/iso_kan_group - 2024 (transparent).png" style="height: 180px; opacity: 80%;" alt="Logo Images" />
    </v-container>
  </div>
</template>

<script>
import HeaderOnePageTwo from "../components/header/HeaderOnePageTwoSiber.vue";
import HeaderOnePageTwoMobile from "../components/header/HeaderOnePageTwoSiberMobile.vue";
import Contact from "../components/contact/Contact";
import Footer from "../components/footer/Footer";
import CrewIdentity from "../assets/plugin/datacrew.js"
import { fetchMethod } from '../plugins/restAPI';

export default {
  components: {
    HeaderOnePageTwo,
    HeaderOnePageTwoMobile,
    Contact,
    Footer,
  },
  props: {
    name: String,
    phone: String,
    email: String,
    job_position: String,
    address: String,
    photoKaryawan: String,
  },
  mounted() {
    // console.log('kdowakdpoawkdopakopdwa', this.photoKaryawan)
    console.log(this.getImage())
  },
  data() {
    return {
      urlFile: 'https://inplatform.sibernetik.co.id/siber-web/uploads/',
      brandImages:
      [
        {
          src: require("../assets/images/service/principal/attunity2.webp"),
          url: "#",
        },
        {
          src: require("../assets/images/service/principal/elastic2.webp"),
          url: "#",
        },
        {
          src: require("../assets/images/service/principal/imply.webp"),
          url: "#",
        },
        {
          src: require("../assets/images/service/principal/aerospike2.webp"),
          url: "#",
        },
        {
          src: require("../assets/images/service/principal/confluent2.webp"),
          url: "#",
        },
      ]
    };
  },
  methods: {
    downloadFile() {
      let url = this.urlFile + this.email + '.vcf'
      fetchMethod(url).then(res => {
          const a = document.createElement('a');
          a.href = URL.createObjectURL(res);
          a.download = url.split('/')[url.split('/').length - 1].split('.')[0];
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
      })
    },
    getImage() {
      try {
        let image = require(`../assets/images/crew/${this.photoKaryawan}.webp`)
        return this.photoKaryawan + '.webp'
      } catch (error) {
        return 'default.png'
      }
    }
  }
};
</script>
<style scooped>
.v-btn.no-hover-effect:hover {
  background-color: inherit !important;
  color: inherit !important;
}

.disc {
  font-size: 16px;
  color: #e8ebf0;
  /* background-color: #2f3a3f; */
}
.styling-principal {
  font-size: 24px;
  color: #ffff;
  font-weight: 700;
  margin: auto;
  font-family: 'Montserrat', sans-serif;
}
.card-styling {
  padding-bottom: 50px !important;
  border-radius:15px !important;
  border: 1px solid #ccc;
  transition: box-shadow .3s;
  background: #fff;
  position: relative;
}
.card-styling:hover {
  box-shadow: 0 0 10px rgba(33,33,33,.2); 
}
</style>
