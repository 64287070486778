<template>
  <div>
    <!-- Start Header Area -->
    <!-- <HeaderOnePageTwo class="d-xs-none d-sm-none d-md-flex hidden-mobile"/>
    <HeaderOnePageTwoMobile class="d-xs-flex d-sm-flex d-md-none"/> -->
    <!-- End Header Area -->
    <!-- test 123 -->

    <!-- Start Contact Area  -->
    <div
      class="rn-contact-area rn-section-gapsiber2 idcard-page"
      v-if="dataDiri"
    >
      <div class="contact-form--1">
        <div class="container" v-if="dataDiri">
          <CardNameCrew
            :name="dataDiri.name"
            :job_position="dataDiri.job_position"
            :phone="dataDiri.phone"
            :email="dataDiri.email"
            :address="dataDiri.address"
            :photoKaryawan="dataDiri.photoKaryawan"
          />
        </div>
      </div>
    </div>
    <div
      v-else
      class="rn-contact-area rn-section-gapsiber2 idcard-page"
      style="display: flex; justify-content: center; align-items: center"
    >
      <div>
        <v-card
          class="ma-auto"
          width="400"
          color="#333333"
          light
          style="position: relative"
        >
          <v-card-text
            class="d-flex flex-column justify-center"
            style="position: relative"
          >
            <div class="d-flex flex-row justify-center pa-10">
              <span class="mx-10 disc">Crew Not Found</span>
            </div>
            <v-btn v-on:click="$router.push('/')" color="#dd8732">
              <v-icon color="white">mdi-keyboard-return</v-icon>
              <span style="color: white">Return To Homepage</span>
            </v-btn>
          </v-card-text>
        </v-card>
      </div>
    </div>
    <!-- End Contact Area  -->
  </div>
</template>

<script>
import HeaderOnePageTwo from "../components/header/HeaderOnePageTwoSiber.vue";
import HeaderOnePageTwoMobile from "../components/header/HeaderOnePageTwoSiberMobile.vue";
import Contact from "../components/contact/Contact";
import Footer from "../components/footer/Footer";
import CardNameCrew from "./Crew-people.vue";
import { crewDigitalCard } from "../assets/plugin/datacrew";

export default {
  components: {
    HeaderOnePageTwo,
    HeaderOnePageTwoMobile,
    Contact,
    Footer,
    CardNameCrew,
  },
  beforeMount() {
    let vm = this;
    // console.log("slebew", crewDigitalCard(vm.$route.params.name));
    let dataDiri = crewDigitalCard(vm.$route.params.name);
    // console.log(!dataDiri);
    if (!dataDiri) {
      // this.$router.push('/')
    }
    vm.dataDiri = dataDiri;
    console.log("ini ada di crew-id", vm.dataDiri);
  },
  data() {
    return {
      dataDiri: {},
      breadcrumbs: [
        {
          text: "Home",
          to: "/",
          disabled: false,
        },
        {
          text: "Contact Form",
          to: "",
          disabled: true,
        },
      ],
    };
  },
};
</script>
<style lang="scss" scooped>
.container {
  display: block;
  margin: auto;
  // text-align: left;
  border-radius: 10px;
}
.idcard-page {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(../assets/images/bg/15410.jpg);
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;
  height: 100vh;
  width: 100vw;
  overflow: auto;
}
</style>